import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as pbi from 'powerbi-client';
import './App.css';

const ViewDashboard = () => {
  const navigate = useNavigate();
  const [embedData, setEmbedData] = useState(null);
  const effectRan = useRef(false);

  const handleLogout = () => {
    // Clear localStorage items
    localStorage.removeItem('refresh');
    localStorage.removeItem('selectedWorkspaceId');
    localStorage.removeItem('selectedReportId');

    navigate('/login');
  };

  const getEmbed = async () => {
    // Try to get the refresh token from local storage
    try {
      const refresh = localStorage.getItem('refresh') || '';
      const refreshRequestBody = JSON.stringify({ refresh });

      // Get a new access token
      const newTokenResponse = await fetch('https://burbackend.azurewebsites.net/users/api/v1/token/refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: refreshRequestBody,
      });

      const newToken = await newTokenResponse.json();
      localStorage.setItem('jwt', newToken.access);

      const response = await fetch(
        'https://burbackend.azurewebsites.net/dashboards/api/v1/embed/' +
        localStorage.getItem('selectedWorkspaceId') +
        '/' +
        localStorage.getItem('selectedReportId') +
        '/',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      redirect('/login');
    }
  };

  const redirect = (path) => {
    navigate(path);
  };

  const embedDashboard = async () => {
    try {
      const data = await getEmbed();
      setEmbedData(data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (!effectRan.current) {
      embedDashboard();
    }

    effectRan.current = true;
  }, []); // Only run once when the component mounts

  useEffect(() => {
    if (embedData) {
      // Embed the Power BI dashboard here
      const config = {
        type: 'report',
        id: embedData.reports[0].id,
        embedUrl: embedData.reports[0].embedUrl,
        accessToken: embedData.token,
        tokenType: pbi.models.TokenType.Embed,
      };

      const embedContainer = document.querySelector('.report-div');
      if (embedContainer) {
        // eslint-disable-next-line
        const dashboard = powerbi.embed(embedContainer, config);
        dashboard.off('loaded'); // Prevent any additional event listeners
      }
    }
  }, [embedData]);

  return (
    <>
      <div className="horizontal-bar">
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
      <div className="dashboard-container">
        <div className="report-div"></div>
      </div>
    </>
  );
};

export default ViewDashboard;
