import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

const SelectDashboard = () => {
  const navigate = useNavigate();
  const [dashboards, setDashboards] = useState([]);
  var [refresh, setRefresh] = useState('');

  const handleButtonClick = (report_id) => {
    localStorage.setItem('selectedReportId', report_id.toString());
  };

  const setWorkspace = (workspace_id) => {
    localStorage.setItem('selectedWorkspaceId', workspace_id.toString());
    redirect('/dashboard');
  };

  const getDashboards = async () => {
    try {
        // Try to get the refresh token from local storage
        try {
          setRefresh(localStorage.getItem('refresh') || '');
          refresh = '{ "refresh": "' + localStorage.getItem('refresh') + '" }';
        } catch (error) {
          redirect('/login');
        }
        // Get a new access token
        const newTokenResponse = await fetch('https://burbackend.azurewebsites.net/users/api/v1/token/refresh/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: refresh,
        });

        var newToken = await newTokenResponse.json();
        localStorage.setItem('jwt', newToken.access);

        var response = await fetch('https://burbackend.azurewebsites.net/dashboards/api/v1/dashboards/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        });
      const data = await response.json();
      return data;
    }
    catch (error) {
      throw error;
    }
  };

  function redirect(path) {
    return navigate(path);
  };

  useEffect(() => {
    const fetchDashboards = async () => {
      try {
        const dashboardsData = await getDashboards();
        setDashboards(dashboardsData);

        if (dashboardsData.length === 1) {
          // Automatically redirect to the only dashboard's details page
          handleButtonClick(dashboardsData[0].report_id);
          setWorkspace(dashboardsData[0].workspace_id.toString());

        }
      } catch (error) {
        // Handle the error, show a message, or perform any necessary action
      }
    };

    fetchDashboards();
  }, []);

  return (
    <div className="container">
      <div className="card">
        <h2>Select Dashboard</h2>
        <div className="center-buttons">
          {dashboards.map((dashboard) => (
            <button type='submit' key={dashboard.report_id} onClick={() => {
              handleButtonClick(dashboard.report_id);
              setWorkspace(dashboard.workspace_id.toString());
            }}>
              {dashboard.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectDashboard;