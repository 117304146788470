import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Make the POST request
    fetch('https://burbackend.azurewebsites.net/users/api/v1/token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        // On success
        localStorage.setItem('jwt', data.access);
        localStorage.setItem('refresh', data.refresh);
        if (data.access) {
          redirect('/select-dashboard');
        }
      })
      .catch(() => {
        // Handle errors here
      });
  };

  function redirect(path) {
      return navigate(path);
    };


    return (
      <div className="main-container">
        <div className="left-box">
          <img src="/ZapataBlanco.png" alt="Logo"/>
        </div>
        <div className="right-box">
          <form onSubmit={handleSubmit}>
            <div className='right-box-label'>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='right-box-label2'>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    );
  };

export default Login;
